import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React from 'react'
import BaseButton from 'src/components/Buttons/BaseButton'
import Carousel from 'src/components/Carousel/Carousel'
import aboutSpotMe from '../../../public/images/home-2-0-1.png'
import aboutSpotMeTablet from '../../../public/images/home-2-0-2.png'
import aboutSpotMeMobile from '../../../public/images/home-2-0-3.png'
import { RedirectUrls } from '../../types/types'

const HostFeatures = ({
  title,
  description
}: {
  title: string
  description: string
}) => {
  return (
    <div className="col-span-3 flex min-w-[100vw] flex-col gap-[14px] px-7 pb-4 md:col-span-1 md:w-full md:min-w-full md:px-0 sm-md:w-full sm-md:min-w-full sm-md:px-0">
      <p className="max-w-60 text-xl font-bold text-black-400">{title}</p>
      <p className="text-[15px] text-black-400">{description}</p>
    </div>
  )
}

const About = () => {
  const { t } = useTranslation('landing')
  const POST_FEATURES = t('INDEX_ABOUT.FEATURES', { returnObjects: true })
  return (
    <section className="flex w-full justify-center bg-grey-300 py-[80px] xxl:py-[90px]">
      <div className="flex w-full max-w-[1832px] flex-col items-center gap-11">
        <h3 className="w-full px-5 text-center text-[32px] font-semibold leading-9 text-black-400">
          {t('INDEX_ABOUT.HEADING')}
        </h3>
        <div className="relative flex w-full items-center justify-center overflow-hidden px-5 md:px-0 lg:px-0 sm-md:px-0">
          <Image
            src={aboutSpotMe}
            alt="happy people posting their spaces"
            sizes="100vw"
            loading="lazy"
            quality={100}
            className="hidden min-h-[289px] max-w-[inherit] lg:block"
          />
          <Image
            src={aboutSpotMeTablet}
            alt="happy people posting their spaces"
            sizes="100vw"
            loading="lazy"
            quality={100}
            className="hidden min-w-min md:block lg:hidden sm-md:block"
          />
          <Image
            src={aboutSpotMeMobile}
            alt="happy people posting their spaces"
            sizes="100%"
            loading="lazy"
            quality={100}
            className="block md:hidden sm-md:hidden"
          />
        </div>
        <div className="hidden grid-cols-3 gap-12 px-5 md:grid lg:px-7 xl:px-12 sm-md:grid">
          {POST_FEATURES.map(({ title, description }, index) => (
            <HostFeatures
              key={`host-feature-${index}`}
              title={title}
              description={description}
            />
          ))}
        </div>
        <div className="w-full md:hidden sm-md:hidden">
          <Carousel
            carouselOptions={{ loop: true }}
            carouselOptionsDesktop={{ align: 'start' }}
            items={POST_FEATURES}
            alignDots="justify-start"
            showButtons
            showDots
          >
            {({ item }) => (
              <HostFeatures
                key={`host-feature-${item.title}`}
                title={item.title}
                description={item.description}
              />
            )}
          </Carousel>
        </div>
        <div className="flex w-full flex-col flex-wrap items-center justify-center sm:flex-row">
          <BaseButton
            fontSize={14}
            width={263}
            size="medium"
            color="primary"
            variant="contained"
            rounded
            href={RedirectUrls.Search}
          >
            {t('INDEX_STEPS.BUTTON_SEARCH')}
          </BaseButton>
        </div>
      </div>
    </section>
  )
}

export default React.memo(About)
